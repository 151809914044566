import Drift from "drift-zoom"

export function initDriftZoom() {
  // Zoom on Desktop
  if (window.innerWidth > 1200) {
    const productImages = document.querySelectorAll(".product-page__product-image")

    productImages.forEach(imgSelector => {

      new Drift(imgSelector, {
        paneContainer: document.querySelector(".zoom-pane"),
      })
    })
  }
}